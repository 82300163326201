<div class="arrows-container" [ngClass]="current">
  <span
    *ngIf="current === 'asc' || !current && firstSort === 'asc'; else downArrow"
    class="material-symbols-outlined arrow up"
    >north</span
  >
  <ng-template #downArrow>
    <span class="material-symbols-outlined arrow down">south</span>
  </ng-template>
</div>
