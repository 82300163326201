<form>
    <fieldset class="button-group-container">
        <div class="options-container">
            <app-radio-input
                *ngFor="let option of options; let i = index"
                class="radio-input-component"
                [ngClass]="conditionalClasses(option, i)"
                [option]="option"
                [group]="group"
                [isStyledRadio]="isStyledRadio"
                (selection)="onSelect(option)"
            ></app-radio-input>
        </div>
    </fieldset>
</form>
