<div class="radio-input-container">
    <input
        class="radio-input"
        [ngClass]="{ 'hidden-input': isStyledRadio }"
        type="radio"
        [name]="group"
        [value]="option.label | formatForId"
        [id]="uniqueId"
        [attr.aria-labelledby]="'label-' + uniqueId"
        [checked]="option.selected"
        (click)="onClick()"
    />
    <label
        class="radio-input-label"
        [ngClass]="{ selected: option.selected }"
        [for]="uniqueId"
        [id]="'label-' + uniqueId"
    >
        <app-svg-icon
            *ngIf="isStyledRadio"
            class="icon"
            [ngClass]="{ 'radio-selected-icon': option.selected, 'radio-unselected-icon': !option.selected }"
            [name]="getIconName()"
        ></app-svg-icon>
        <span class="label-text">{{ option.label }}</span>
    </label>
</div>
