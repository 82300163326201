<div class="checkbox-input-container">
    <input
        class="checkbox-input"
        [ngClass]="{ 'hidden-input': isStyledCheckbox }"
        type="checkbox"
        [name]="group"
        [value]="option.label | formatForId"
        [id]="uniqueId"
        [attr.aria-labelledby]="'label-' + uniqueId"
        [checked]="option.selected"
    />
    <label
        class="checkbox-input-label"
        [ngClass]="{ selected: option.selected }"
        [for]="uniqueId"
        [id]="'label-' + uniqueId"
        (click)="onClick()"
    >
        <app-svg-icon
            *ngIf="isStyledCheckbox"
            class="icon"
            [ngClass]="{ 'checkbox-selected-icon': option.selected, 'checkbox-unselected-icon': !option.selected }"
            [name]="getIconName()"
        ></app-svg-icon>
        <span class="label-text">{{ option.label }}</span>
    </label>
</div>
