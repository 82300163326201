<p>styles-display works!</p>
<p class="h1-placeholder">I am the H1 placeholder</p>
<p class="h2-placeholder">I am the H2 placeholder</p>
<p class="h3-placeholder">I am the H3 placeholder</p>
<p class="h4-placeholder">I am the H4 placeholder</p>
<p class="h5-placeholder">I am the H5 placeholder</p>
<p class="subtitle1-placeholder">I am the subtitle1 placeholder</p>
<p class="subtitle2-placeholder">I am the subtitle2 placeholder</p>
<p class="body1-placeholder">I am the body1 placeholder</p>
<p class="body2-placeholder">I am the body2 placeholder</p>
<p class="button-placeholder">I am the button placeholder</p>
<p class="caption-placeholder">I am the caption placeholder</p>
<p class="overline-placeholder">I am the overline placeholder</p>
<p class="small-caps-instructions">I am small caps instructions</p>
<p class="instructions">I am instructions</p>
